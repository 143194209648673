import React from 'react'

export default function 
() {
  return (
    <div className='d-flex flex-row justify-content-center align-items-center' style={{height: "100vh"}} >
    <div className='card text-center m-2 blur p-2 centered-row width-form'
    style={{ boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)", backgroundColor: "rgb(255,255,255,0.4)", height: "70vh" }}>
        <h3 style={{color: "white"}}>Stream Ended, Thanks for Watching</h3>
    </div>
    </div>
  )
}
